import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAnimateNumber from 'vue-animate-number'
import './utils/flexible.js'
import dataV from '@jiaminghi/data-view'
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap,{
  ak:'yXHvNAu1EK4mX9HXWin66bVlhecUawc9',
})
Vue.use(dataV)
Vue.use(VueAnimateNumber)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
